<!-- 网关通信状态 -->
<template>
  <div id="gateway">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>网关通信状态</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <el-form-item label="变配电站：">
            <el-input  v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="checked">只显示断线状态</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <el-table class="table" :data="tableData">
        <el-table-column align="center" prop="id" label="序号">
        </el-table-column>
        <el-table-column align="center" prop="name" label="变配电站名称">
        </el-table-column>
        <el-table-column align="center" prop="zero" label="网关代码">
        </el-table-column>
        <el-table-column align="center" prop="one" label="网关名称">
        </el-table-column>
        <el-table-column align="center" prop="two" label="网关类型">
        </el-table-column>
        <el-table-column align="center" prop="three" label="最后上传时间">
        </el-table-column>
        <el-table-column align="center" prop="four" label="最后采集时间">
        </el-table-column>
        <el-table-column align="center" prop="type" label="是否断线">
          <template slot-scope="scope">
              
                <img
                  v-if="scope.row.type == 0"
                  class="trend"
                  src="../../assets/images/environment/not-connect.png"
                  alt=""
                />
                <img
                  v-else
                  class="trend"
                  src="../../assets/images/environment/connect.png"
                  alt=""
                />
              
            </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page">首页</button>
        <el-pagination
          :current-page="requestParam.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="changeSize"
          @current-change="changeCurrent"
        >
        </el-pagination>
        <button class="tail-page">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input size="mini" class="pagination-input"></el-input>
          <span class="text">页</span>
          <button class="confirm">确定</button>
        </div>
      </div>
    </div>
    <el-dialog title="变配电站" :visible.sync="dialogVisible">
      <div class="dialog-content">
        <div class="content-left">
          <ul class="tabs">
            <li
              v-for="(item, index) in tabsData"
              :key="index"
              :class="select === index ? 'select' : ''"
              @click="switchTabs(index)"
            >
              {{ item }}
            </li>
          </ul>
          <div class="search">
            <el-input placeholder="请输入内容"> </el-input>
          </div>
          <ul class="select">
            <li v-for="(item, index) in selectData" :key="index">
              <input type="checkbox" /><span>{{ item }}</span>
            </li>
          </ul>
        </div>
        <div class="content-right">
          <div class="query">
            <el-input placeholder="请输入检索内容"> </el-input>
            <el-button type="primary">全部</el-button>
          </div>
          <el-table class="table" :data="tableData">
            <el-table-column align="center" prop="name" label="变配电站">
            </el-table-column>
            <el-table-column align="center" prop="zero" label="组织机构">
            </el-table-column>
            <el-table-column align="center" prop="one" label="区域">
            </el-table-column>
            <el-table-column align="center" prop="two" label="用户地址">
            </el-table-column>
          </el-table>
          <div class="pagination">
            <button class="home-page">首页</button>
            <el-pagination
              :current-page="requestParam.pageNum"
              :page-sizes="[10, 20, 30, 40]"
              layout="prev, pager, next, sizes,slot"
              prev-text="上一页"
              next-text="下一页"
              :total="total"
              @size-change="changeSize"
              @current-change="changeCurrent"
            >
            </el-pagination>
            <button class="tail-page">尾页</button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button>取消</el-button>
        <el-button type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
import * as echarts from "echarts";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      selectData: [
        "全部产权单位",
        "北京鸿运承物业",
        "第一客运分公司",
        "第二客运分公司",
        "第三客运分公司",
        "第四客运分公司",
        "第五客运分公司",
        "第六客运分公司",
        "第七客运分公司",
        "第八客运分公司",
        "第九客运分公司",
        "电车客运分公司",
        "保修分公司",
        "燃料供应分公司",
        "产权单位",
      ],
      tabsData: ["组织结构", "区域"],
      select: 0,
      tableData: [
        {
          id: 1,
          name: "总部A楼配电室",
          zero: "10100000101",
          one: "监控网关",
          two: "ANet-YW4E16",
          three: "2023-09-21 09:29:14",
          four: "2023-09-21 09:29:14",
          type:0
        },
        {
          id: 2,
          name: "总部A楼配电室",
          zero: "10100000101",
          one: "监控网关",
          two: "ANet-YW4E16",
          three: "2023-09-21 09:29:14",
          four: "2023-09-21 09:29:14",
          type:1,
        },
      ],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dialogVisible: false,
    };
  },

  mounted() {
  },

  methods: {
    changeSize(pageSize) {
      this.requestParam.pageNum = pageSize;
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#gateway {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 24px 18px 30px 36px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* 查询表单 */
.query-form {
  width: 1570px;
  height: 62px;
  border-radius: 3px;
  background-color: rgba(6, 29, 74, 0.79);
  padding-left: 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0 15px 0 0;
}
/* input */
.form :deep() .el-input .el-input__inner {
  width: 176px;
  background-color: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}
.dialog-content {
  display: flex;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
/* tabs */
.tabs {
  display: flex;
}
.tabs li {
  width: 151px;
  height: 47px;
  border-radius: 3px;
  background-color: #09244f;
  font-family: Microsoft YaHei;
  color: #c9d4f1;
  font-size: 14px;
  text-align: center;
  line-height: 47px;
}
.content-left {
  width: 303px;
  height: 610px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
}
.tabs .select {
  background-color: #1c3d7d;
  color: #ffc74a;
}

.search :deep() input {
  width: 262px;
  height: 35px;
  border: 1px solid #09295b;
  background-color: #09244d;
  color: #aed6ff;
  font-size: 13px;
  margin: 18px 0 16px 20px;
}
.select {
  width: 262px;
  height: 474px;
  padding: 2px 2px 2px 2px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  margin-left: 20px;
}
.select li {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
.select input {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: #176ad2;
}
.select span {
  color: #aed6ff;
  font-size: 14px;
}
.content-right {
  width: 837px;
  height: 610px;
  padding: 16px 0 0 21px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
  margin-left: 15px;
}
.query {
  display: flex;
  margin-bottom: 14px;
}
.query :deep() .el-input {
  width: 347px;
}
.query :deep() input {
  width: 347px;
  height: 35px;
  border-radius: 2px;
  border: 1px solid #09295b;
  background-color: #09244d;
  font-size: 13px;
  color: #aed6ff;
}

.query :deep() button {
  width: 68px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid rgba(121, 121, 121, 0);
  background-color: #176ad2;
  box-sizing: border-box;
  color: #fdfdfd;
  text-align: center;
  line-height: 36px;
  padding: 0;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}
/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}
.pagination-input >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}
.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}

</style>
